import {
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';

import userReducer from "./userSlice";
import globalReducer from "./globalSlice";
import newslettersReducer from "./newslettersSlice";

const combinedReducer = combineReducers({
  user: userReducer,
  global: globalReducer,
  newsletters: newslettersReducer
});

const rootReducer = (state, action) => {
  // Post-logout cleanup
  if (action.type === 'profile/logoutUser/fulfilled') {
    // by setting the state to 'undefined' here, we effectively reset each
    // individual reducer to its initial state
    state = {};
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;