import {useDispatch, useSelector} from "react-redux";
import {
  clearReviews,
  getNewsletter, getReviews, incrementPageNumber, incrementReviewsPageNumber, resetReviewsPageNumber, selectAllReviews,
  selectCurrentNewsletter,
  selectCurrentReview, selectCurrentReviewSummary, selectIsReviewsLoading,
  selectLoadingCurrentNewsletter, selectPageReviewNumber
} from "../store/newslettersSlice";
import {useCallback, useEffect, useRef, useState} from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import {useParams} from "react-router-dom";
import {classNames, getImageSrc} from "../utilities";
import {StarIcon} from "@heroicons/react/20/solid";
import NewReviewModal from "./NewReviewModal";
import ReviewCard from "../components/ReviewCard";
import DeleteReviewModal from "../components/DeleteReviewModal";
import {selectProfile} from "../store/userSlice";
import {setSigninPromptOpen} from "../store/globalSlice";
import twitterLogo from "../twitter.png"
import {IndustryBadge} from "../components/IndustryBadge";
import {useNavigate} from "react-router-dom";

const FREQUENCY_DISPLAY = {
  "DAILY": "every day",
  "WEEKLY": "every week",
  "BIWEEKLY": "every two weeks",
  "MONTHLY": "every month",
}

export default function NewsletterPage() {

  const { newsletterSlug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedIn = useSelector(selectProfile) != null;
  const isLoading = useSelector(selectLoadingCurrentNewsletter);
  const isLoadingReviews = useSelector(selectIsReviewsLoading);
  const newsletter = useSelector(selectCurrentNewsletter);
  const myReview = useSelector(selectCurrentReview);
  const allReviews = useSelector(selectAllReviews);
  const reviewPageNumber = useSelector(selectPageReviewNumber);
  const currentReviewSummary = useSelector(selectCurrentReviewSummary) || {
    averageScore: 0,
    ratingCounts: {}
  };

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [ratingFilter, setRatingFilter] = useState(null);

  const totalReviews = currentReviewSummary ? (Object.values(currentReviewSummary.ratingCounts).reduce((s, t) => s+t, 0)) : 100;

  var postingByline = ""
  if (newsletter && newsletter.postFrequency && (newsletter.postFrequency in FREQUENCY_DISPLAY)) {
    postingByline += " " + FREQUENCY_DISPLAY[newsletter.postFrequency]
  }
  if (newsletter && newsletter.newsletterType && newsletter.newsletterType.name !== "Other") {
    postingByline += " " + "on " + newsletter.newsletterType.name
  }
  if (postingByline !== "") {
    postingByline = "Posting " + postingByline
  }

  // This is weird but used to avoid multiple fetches
  const initialized = useRef(false);

  useEffect(() => {
    console.log("Slug changed, fetching newsletter");
    dispatch(getNewsletter(newsletterSlug));
  }, [newsletterSlug, dispatch])

  // Make sure to reset the reviews when a new filter is applied
  useEffect(() => {
    console.log("Parameter changed, fetching reviews")
    dispatch(resetReviewsPageNumber());
    dispatch(clearReviews());
    dispatch(getReviews({newsletterSlug, rating: ratingFilter}))
  }, [ratingFilter, dispatch, isReviewModalOpen, isDeleteModalOpen, newsletterSlug, initialized])

  // When scroll, get new reviews
  useEffect(() => {
    const onScroll = function () {
      if ((window.innerHeight + window.scrollY >= document.body.offsetHeight)) {
        console.log("Bottom of page reached, checking for more pages")
        dispatch(incrementReviewsPageNumber())
        dispatch(getReviews({newsletterSlug, rating: ratingFilter}))
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [dispatch, reviewPageNumber])

  const openTwitterShare = useCallback(() => {
    const test = `Check out what people are saying about my newsletter, ${newsletter.name}, on @NewsletterSurf!\n\n${window.location}`
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(test)}`, '_blank')
  }, [newsletter])

  return (
    <div className="px-6 sm:px-0" key={myReview?.id}>
      <NewReviewModal isOpen={isReviewModalOpen} setIsOpen={setIsReviewModalOpen} />
      <DeleteReviewModal reviewId={myReview?.id} isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen} />
      {isLoading && !newsletter && (
        <div className="w-full text-center mt-32">
          <LoadingSpinner />
        </div>
      )}
      {newsletter && (
        <div className="sm:px-48 pt-16">
          {/* Top Section */}
          <div className="lg:flex lg:items-center lg:justify-between">
            <img src={getImageSrc(newsletter.logoReference)} alt="newsletter image" className="w-16 h-16 rounded-md mr-4" />
            <div className="min-w-0 flex-1">
              <div className="flex justify-between" >
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  {newsletter.name}
                </h2>
                <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="items-center visible lg:hidden rounded-md bg-orange-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    Back
                  </button>
              </div>
              {newsletter.authorName && (
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    Created by
                    {newsletter.authorLink && (
                      <span className="ml-1 text-indigo-600 cursor-pointer font-bold" onClick={() => window.open(newsletter.authorLink, '_blank')} > {newsletter.authorName}</span>
                    )}
                    {!newsletter.authorLink && (
                      <span className="ml-1 font-bold"> {newsletter.authorName}</span>
                    )}
                    <div className="flex flex-row ml-3">
                      <IndustryBadge industry={newsletter.industry} />
                      <div className="flex-1"></div>
                    </div>
                  </div>
                </div>
              )}
              {!newsletter.authorName && (
                <div className="flex flex-row mt-2">
                  <IndustryBadge industry={newsletter.industry} />
                  <div className="flex-1"></div>
                </div>
              )}

            </div>
            <div className="mt-5 lg:ml-4 lg:mt-0">
              <div className="lg:flex">
                <span className="">
                  <button
                    onClick={() => window.open(newsletter.signupLink, '_blank')}
                    type="button"
                    className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Subscribe
                  </button>
                </span>
                {newsletter.homepageLink && (
                  <span className="ml-3">
                  <button
                    onClick={() => window.open(newsletter.homepageLink, '_blank')}
                    type="button"
                    className="border border-1 border-current border-solid border-orange-500 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    Visit Homepage
                  </button>
                </span>
                )}
                <span className="ml-3">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="items-center hidden lg:block rounded-md bg-orange-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    Back
                  </button>
                </span>
              </div>
              <button className="flex float-right font-bold uppercase text-gray-600 text-sm mt-2" onClick={openTwitterShare}>
                Share on <img src={twitterLogo} alt="twitter logo" className="w-4 h-4 ml-2"  />
              </button>
            </div>
          </div>
          {newsletter.description && (
            <div className="mt-10 text-gray-500">
              {newsletter.description}
            </div>
          )}
          {postingByline && (
            <div className="mt-2 text-gray-500 italic text-sm">
              {postingByline}
            </div>
          )}
          {/* Rating Section */}
          <div className="grid gap-x-8 grid-cols-1 lg:grid-cols-12 mt-16">
            <div className="col-span-1 lg:col-span-4">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">Customer Reviews</h2>

              <div className="mt-3 flex items-center">
                <div>
                  <div className="flex items-center">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          currentReviewSummary.averageScore > rating ? 'text-yellow-400' : 'text-gray-300',
                          'h-5 w-5 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <p className="sr-only">{currentReviewSummary.averageScore} out of 5 stars</p>
                </div>
                <p className="ml-2 text-sm text-gray-900">Based on {totalReviews} reviews</p>
              </div>

              <div className="mt-6">
                <h3 className="sr-only">Review data</h3>

                <dl className="">
                  {Object.entries(currentReviewSummary.ratingCounts).reverse().map(([rating, count]) => (
                    <div key={rating}
                         className={`${rating == ratingFilter ? "bg-gray-100" : ""} flex items-center text-sm hover:bg-gray-50 cursor-pointer rounded-md p-2`}
                         onClick={() => setRatingFilter(rating)}>
                      <dt className="flex flex-1 items-center">
                        <p className="w-3 font-medium text-gray-900">
                          {rating}
                          <span className="sr-only"> star reviews</span>
                        </p>
                        <div aria-hidden="true" className="ml-1 flex flex-1 items-center">
                          <StarIcon
                            className={classNames(
                              count > 0 ? 'text-yellow-400' : 'text-gray-300',
                              'h-5 w-5 flex-shrink-0'
                            )}
                            aria-hidden="true"
                          />

                          <div className="relative ml-3 flex-1">
                            <div className="h-3 rounded-full border border-gray-200 bg-gray-100" />
                            {count > 0 ? (
                              <div
                                className="absolute inset-y-0 rounded-full border border-yellow-400 bg-yellow-400"
                                style={{ width: `calc(${count} / ${totalReviews} * 100%)` }}
                              />
                            ) : null}
                          </div>
                        </div>
                      </dt>
                      <dd className="ml-3 w-10 text-right text-sm tabular-nums text-gray-900">
                        {Math.round((count / totalReviews) * 100)}%
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>

              <div className="mt-10">

                {myReview && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-2 flex-1 mb-4">Your Review</h3>
                    <ReviewCard review={myReview} />
                    <button
                      onClick={() => setIsDeleteModalOpen(true)}
                      className="mt-4 float-right rounded-md bg-red-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    >
                      Delete your Review
                    </button>
                  </div>
                )}

                {!myReview && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">Share your thoughts</h3>
                    <p className="mt-1 text-sm text-gray-600">
                      If you’ve read this newsletter, share your thoughts!
                    </p>
                    <button
                      onClick={() => {
                        if (!loggedIn) {
                          dispatch(setSigninPromptOpen(true));
                          return;
                        }
                        setIsReviewModalOpen(true)
                      }}
                      className="mt-6 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-8 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
                    >
                      Write a review
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="col-span-1 lg:col-span-7 lg:col-start-6">
              <h3 className="text-lg font-bold">Recent reviews {ratingFilter ? `(showing ${ratingFilter} star reviews only)` : ""}</h3>
              {ratingFilter && (
                <button onClick={() => setRatingFilter(null)} className="mt-2 rounded-md border border-gray-300 bg-white px-6 py-1 text-sm font-medium text-gray-900 hover:bg-gray-50 w-auto">
                  Clear Star Filter
                </button>
              )}
              {(!allReviews || allReviews.length == 0) && !isLoadingReviews && (
                <div className="mt-12 text-center font-bold text-lg text-gray-500">
                  No reviews - be the first by clicking "Write Review"!
                </div>
              )}
              <div className="flow-root">
                <div className="divide-y divide-gray-200">
                  {allReviews && allReviews.map((review) => (
                    <div className="py-8" key={review.id}>
                      <ReviewCard review={review} />
                    </div>
                  ))}
                </div>

                <div className="w-full text-center mt-16 h-16">
                  {isLoadingReviews && (
                    <LoadingSpinner />
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
}