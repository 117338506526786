import facebook from './images/facebook.png';
import twitter from './images/twitter.png';

export function LegalLinks() {

  return (
    <div className="italic text-sm">
      <a className="hover:text-blue-500" href="https://drive.google.com/file/d/11afakOA8_fGBMbuLiic-b3YBjvMgX4hI/view?usp=sharing" target="_blank">Privacy Policy</a> - <a className="hover:text-blue-500" href="https://drive.google.com/file/d/11afakOA8_fGBMbuLiic-b3YBjvMgX4hI/view?usp=sharing" target="_blank">Terms of Service</a><br />
      <a className="hover:text-green-500" href="https://zbd8fduv5kp.typeform.com/to/Tplmk0qQ" target="_blank">Feedback Form</a> - <a className="hover:text-blue-500" href="https://trello.com/b/IZXfQSj0/newslettersurf" target="_blank">Roadmap</a> - <a className="hover:text-purple-500" href="mailto:newsletter.surf@gmail.com">Contact Us</a>
      <div className="mt-2">
        <a href="https://www.facebook.com/profile.php?id=61551646456869" target="_blank"><img className="w-6 h-6 mr-2 inline" src={facebook} alt="Facebook link"/></a>
        <a href="https://twitter.com/NewsletterSurf" target="_blank"><img className="w-6 h-6 inline" src={twitter} alt="Twitter link"/></a>
      </div>
    </div>
  )

}