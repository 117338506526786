import {useSelector} from "react-redux";
import {selectIndustries} from "../store/globalSlice";
import {classNames} from "../utilities";

const COLOR_OPTIONS = [
  "orange", "indigo", "red", "purple", "green", "pink", "blue", "yellow"
]

export function IndustryBadge({industry}) {

  const industries = useSelector(selectIndustries)?.map(industry => industry.name);
  if (industries) {
    industries.sort((a, b) => a.localeCompare(b));
  }

  return (
    <div className={classNames(`bg-${COLOR_OPTIONS[industries && industries.indexOf(industry.name) % COLOR_OPTIONS.length]}-400`, `rounded-full p-1 px-2 text-xs font-bold uppercase text-white`)}>
      {industry.name}
      {/* This spawn does nothing, just hard loads the colors*/}
      <span className="bg-orange-400 bg-indigo-400 bg-red-400 bg-purple-400 bg-green-400 bg-pink-400 bg-blue-400 bg-yellow-400"></span>
    </div>
  )
}