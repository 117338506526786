import {Fragment, useCallback, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {StarIcon} from "@heroicons/react/20/solid";
import {classNames} from "../utilities";
import {useDispatch, useSelector} from "react-redux";
import {
  createReview,
  getReviews,
  selectCurrentNewsletter,
  selectCurrentReviewIsCreating
} from "../store/newslettersSlice";
import LoadingSpinner from "../components/LoadingSpinner";


export default function NewReviewModal({isOpen, setIsOpen}) {

  const dispatch = useDispatch();
  const newsletter = useSelector(selectCurrentNewsletter);

  const isCreatingReview = useSelector(selectCurrentReviewIsCreating);

  const [hoverRating, setHoverRating] = useState(3);
  const [rating, setRating] = useState(3);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);

  const submitReview = useCallback(() => {
    dispatch(createReview({newsletterId: newsletter.id, reviewParams: {
      title,
        description,
      score: rating,
    }})).then((response) => {
      if (response.error) {
        setError("Unable to create review - try again later.");
      } else {
        setTitle(null);
        setRating(3);
        setDescription(null);
        setIsOpen(false);
      }
    })
  }, [newsletter, title, rating, description, dispatch, setIsOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setIsOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mx-4 sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Write a review
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                      <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                        Review Title <span className="text-gray-500 text-sm">(optional)</span>
                      </label>
                      <div className="mt-2">
                        <input
                          id="title"
                          name="title"
                          type="text"
                          value={title}
                          onChange={(event) => setTitle(event.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                        Description <span className="text-gray-500 text-sm">(optional)</span>
                      </label>
                      <div className="mt-2">
                      <textarea
                        placeholder="Write a few sentences about the newsletter."
                        id="description"
                        name="description"
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                      />
                    </div>
                    </div>
                    <div className="w-full items-center text-center">
                      <div className="mt-4 flex flex-row">
                        {[0, 1, 2, 3, 4].map((r) => (
                          <StarIcon
                            onMouseEnter={() => setHoverRating(r + 1)}
                            onMouseLeave={() => setHoverRating(rating)}
                            onClick={() => setRating(r + 1)}
                            key={r}
                            className={classNames(
                              hoverRating > r ? 'text-yellow-400' : 'text-gray-300',
                              'h-12 w-12 flex-shrink-0 cursor-pointer'
                            )}
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 ml-5">
                  {error && <p className="text-sm text-red-500">{error}</p>}
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={submitReview}
                  >
                    Submit Review
                  </button>
                  <button
                    disabled={isCreatingReview}
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                    {isCreatingReview && <div className="mr-4"><LoadingSpinner /></div> }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}