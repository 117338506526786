import axios from "axios";


const JWT_STORAGE = 'NEWSLETTER_JWT';


export function saveToken(token) {
  localStorage.setItem(JWT_STORAGE, token.credential);
}

export function signOut() {
  localStorage.removeItem(JWT_STORAGE);
  window.location.reload();
}

export function getBearerToken() {
  const jwt = localStorage.getItem(JWT_STORAGE);
  if (jwt) {
    return `Bearer ${jwt}`;
  }
  return null;
}

export function getAxios() {
  const token = getBearerToken();
  if (token) {
    return axios.create({
      baseURL: process.env.REACT_APP_BACKEND_HOST,
      timeout: 5000,
      headers: {
        Authorization: getBearerToken(),
      },
    });
  } else {
    return axios.create({
      baseURL: process.env.REACT_APP_BACKEND_HOST,
      timeout: 5000
    });
  }
}

export function getAxiosNoAuth() {
  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND_HOST,
    timeout: 5000
  });
}

function getProfile() {
  return getAxios().get("/profile");
}

function uploadImage(contentType, formData) {
  return getAxios().post("/newsletter/image", formData, {
    headers: {'Content-Type': contentType},
  });
}

function getIndustries() {
  return getAxiosNoAuth().get("/industry");
}

function getNewsletterTypes() {
  return getAxiosNoAuth().get("/newsletter-type");
}

function createNewsletter(newsletterParams) {
  return getAxios().post("/newsletter", newsletterParams);
}

function getNewsletters(queryParams) {
  let queryString = "";
  if (queryParams) {
    queryString += "?";
    for (const [key, value] of Object.entries(queryParams)) {
      queryString += `${key}=${value}&`;
    }
    queryString = queryString.slice(0, -1);
  }
  return getAxios().get("/newsletter" + queryString);
}

function getNewsletter(slug) {
  return getAxios().get("/newsletter/" + slug);
}

function createReview(newsletterId, reviewParams) {
  return getAxios().post("/review/" + newsletterId, reviewParams);
}

function getReviewsForNewsletter(newsletterSlug, queryParams) {
  let queryString = "";
  if (queryParams) {
    queryString += "?";
    for (const [key, value] of Object.entries(queryParams)) {
      queryString += `${key}=${value}&`;
    }
    queryString = queryString.slice(0, -1);
  }
  return getAxios().get("/review/" + newsletterSlug + queryString);
}

function deleteReview(reviewId) {
  return getAxios().delete("/review/" + reviewId);
}

function upvoteNewsletter(newsletterId) {
  return getAxios().post("/votes/" + newsletterId + "/upvote");
}

function downvoteNewsletter(newsletterId) {
  return getAxios().post("/votes/" + newsletterId + "/downvote");
}

function getSponsoredNewsletters() {
  return getAxiosNoAuth().get("/newsletter/sponsored");
}

const client = {
  getProfile,
  uploadImage,
  getIndustries,
  getNewsletterTypes,
  createNewsletter,
  getNewsletters,
  getNewsletter,
  createReview,
  getReviewsForNewsletter,
  deleteReview,
  upvoteNewsletter,
  downvoteNewsletter,
  getSponsoredNewsletters
}

export default client;