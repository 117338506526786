

import {Fragment, useCallback, useState} from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import {
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline'
import {useDispatch, useSelector} from "react-redux";
import {
  clearSearchResults,
  getNewsletters,
  searchNewsletters,
  selectNewsletterSearchLoading,
  selectNewsletterSearchResults
} from "../store/newslettersSlice";
import {classNames, getImageSrc, getNewsletterPage} from "../utilities";
import Rating from "./Rating";
import LoadingSpinner from "./LoadingSpinner";
import {useNavigate} from "react-router-dom";

export default function SearchModal({open, setOpen}) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState('')


  const searchResults = useSelector(selectNewsletterSearchResults);
  const isLoadingSearchResults = useSelector(selectNewsletterSearchLoading);

  const handleSearch = useCallback((e) => {
    setQuery(e.target.value);
    if (e.target.value.trim().length > 0) {
      dispatch(searchNewsletters(e.target.value))
    } else {
      dispatch(clearSearchResults());
    }

  }, []);

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={(newsletter) => {
                navigate(getNewsletterPage(newsletter))
                setOpen(false);
              }}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search for a newsletter..."
                    onChange={handleSearch}
                  />
                  {isLoadingSearchResults && (
                    <div className="absolute right-4 top-3.5">
                      <LoadingSpinner size={5} />
                    </div>
                  )}
                </div>

                {searchResults && (
                  <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                    {searchResults.map((newsletter) => (
                      <Combobox.Option
                        key={newsletter.id}
                        value={newsletter}
                        className={({ active }) =>
                          classNames('flex cursor-pointer select-none rounded-xl p-3', active && 'bg-gray-100')
                        }
                      >
                        {({ active }) => (
                          <>
                            <img src={getImageSrc(newsletter.logoReference)} className="h-10 w-10 rounded-lg" />
                            <div className="ml-4 flex-auto">
                              <p
                                className={classNames(
                                  'text-sm font-medium',
                                  active ? 'text-gray-900' : 'text-gray-700'
                                )}
                              >
                                {newsletter.name}
                              </p>
                              <Rating rating={newsletter.averageScore} />
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== '' && (!searchResults || searchResults.length == 0) && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <ExclamationCircleIcon
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-6 w-6 text-gray-400"
                    />
                    <p className="mt-4 font-semibold text-gray-900">No results found</p>
                    <p className="mt-2 text-gray-500">No newsletters found for this search term. Please try again, or add the newsletter yourself!</p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}