import {StarIcon} from "@heroicons/react/20/solid";
import {classNames} from "../utilities";
import {DateTime} from "luxon";
import defaultProfilePicture from "../profile.jpeg";


export default function ReviewCard({review}) {
 return (
   <div key={review.id}>
     <div className="flex">
       <img src={review.creator.picture || defaultProfilePicture} alt={`${review.creator.name}.`} className="h-16 w-16 rounded-full" />
       <div className="ml-4">
         <h4 className="text-sm font-bold text-gray-900">{review.title}</h4>
         <div className="mt-1 flex items-center">
           {[0, 1, 2, 3, 4].map((rating) => (
             <StarIcon
               key={review.id + "-" + review.score}
               className={classNames(
                 review.score > rating ? 'text-yellow-400' : 'text-gray-300',
                 'h-5 w-5 flex-shrink-0'
               )}
               aria-hidden="true"
             />
           ))}
         </div>
         <h4 className="text-sm text-gray-500 mt-1">Reviewed by {review.creator.name} {DateTime.fromISO(review.createdDate).toRelative()}</h4>
         <div className="text-gray-700 text-sm mt-2">
           {review.description}
         </div>
       </div>
     </div>


   </div>
 );
}