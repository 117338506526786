import {Fragment, useEffect, useState} from 'react'
import {Menu, Popover, Transition} from '@headlessui/react'
import {MagnifyingGlassIcon} from '@heroicons/react/20/solid'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import {GoogleLogin} from "@react-oauth/google";
import {useDispatch, useSelector} from "react-redux";
import {getProfile, selectProfile} from "./store/userSlice";
import {saveToken, signOut} from "./client";
import {useNavigate} from "react-router-dom";
import SearchModal from "./components/SearchModal";
import newsletterLogo from "./newsletterlogo.png";
import {setSigninPromptOpen} from "./store/globalSlice";
import defaultProfilePicture from "./profile.jpeg";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);

  const [searchIsOpen, setSearchIsOpen] = useState(false);

  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  const loggedIn = (googleData) => {
    saveToken(googleData);
    window.location.reload();
  }

  return (
    <div className="fixed z-50 w-full">
      <SearchModal open={searchIsOpen} setOpen={setSearchIsOpen}/>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({open}) =>
          classNames(
            open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
            'bg-white shadow-sm lg:static lg:overflow-y-visible'
          )
        }
      >
        {({open}) => (
          <>
            <div className="mx-auto py-4 px-6 xl:px-48">
              <div className="relative flex justify-between items-center">
                <button className="cursor-pointer flex flex-row" onClick={() => navigate("/")}>
                  <img
                    className="block h-8 w-auto"
                    src={newsletterLogo}
                    alt="Your Company"
                  />
                  <div className="logo-font mx-4 text-2xl">
                    newsletter.surf
                  </div>
                </button>
                <div className="hidden md:block w-full min-w-0 flex-1 px-8 items-center">
                  <div className="w-full">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                      </div>
                      <input
                        onClick={(ev) => {
                          setSearchIsOpen(true)
                          ev.preventDefault();
                        }}
                        onChange={(ev) => ev.target.value = ""}
                        id="search"
                        name="search"
                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Popover.Button
                    className="-mx-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                    )}
                  </Popover.Button>
                </div>
                <div className="hidden lg:flex lg:items-center lg:justify-end">

                  {/* Profile dropdown */}
                  {profile &&
                    <Menu as="div" className="relative ml-5 flex-shrink-0">
                      <div>
                        <Menu.Button
                          className="flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <img className="h-8 w-8 rounded-full" src={profile.picture || defaultProfilePicture} alt=""/>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={() => signOut()}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'w-full block px-4 py-2 text-sm text-gray-700 text-left'
                                )}
                              >
                                Sign Out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  }
                  {!profile &&
                    <GoogleLogin onSuccess={loggedIn} />
                  }
                  <button
                    onClick={() => setSearchIsOpen(true)}
                    className="ml-6 items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Add a Review
                  </button>
                  <button
                    onClick={() => {
                      if (profile == null) {
                        dispatch(setSigninPromptOpen(true));
                        return;
                      }
                      navigate("/create")
                    }}
                    className="ml-4 items-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    Add a Newsletter
                  </button>
                </div>
              </div>
            </div>

            <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="border-t border-gray-200 pb-3 pt-4">
                {profile &&
                  <div>
                    <Popover.Button className="w-full mx-auto mt-3 space-y-1 px-2 px-4 text-center">
                      <button
                        onClick={() => {setSearchIsOpen(true)}}
                        className="w-full block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                      >
                        Search Newsletters
                      </button>
                    </Popover.Button>
                    <Popover.Button className="w-full mx-auto mt-3 space-y-1 px-2 px-4 text-center">
                      <button
                        onClick={() => {
                          if (profile == null) {
                            dispatch(setSigninPromptOpen(true));
                            return;
                          }
                          navigate("/create")
                        }}
                        className="w-full block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                      >
                        Add a Newsletter
                      </button>
                    </Popover.Button>
                    <div className="mx-auto mt-3 max-w-3xl space-y-1 px-2 px-4">
                      <button
                        onClick={() => signOut()}
                        className="w-full block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                      >
                        Sign Out
                      </button>
                    </div>
                  </div>
                }
                {!profile &&
                  <div>
                    <Popover.Button className="w-full mx-auto mt-3 space-y-1 px-2 px-4 text-center">
                      <button
                        onClick={() => {setSearchIsOpen(true)}}
                        className="w-full block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                      >
                        Search Newsletters
                      </button>
                    </Popover.Button>
                    <div className="text-center mx-auto w-[300px] flex justify-center">
                      <GoogleLogin 
                        onSuccess={loggedIn}
                        width='290px'
                      />
                    </div>
                  </div>
                }
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  )
}