import {StarIcon} from "@heroicons/react/20/solid";
import {classNames} from "../utilities";


export default function Rating({rating, includeScoreText}) {

  const stars = [0, 1, 2, 3, 4].map((r) => (
    <StarIcon
      key={r}
      className={classNames(
        rating > r ? 'text-yellow-400' : 'text-gray-200',
        'h-5 w-5 flex-shrink-0'
      )}
    />
  ))

  return (
    <div className="flex items-center xl:col-span-1">
      <div className="flex items-center">
        {stars}
      </div>
      {rating !== null &&
        (
          <p className="ml-3 text-sm text-gray-700">
            {rating}
            <span className="sr-only"> stars</span>
          </p>
        )
      }
      {rating === null &&
        (
          <p className="ml-3 text-sm text-gray-700">
            {rating}
            <span> No ratings yet</span>
          </p>
        )
      }

    </div>
  )
}