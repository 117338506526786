import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from "../client";

const initialState = {
  industries: null,
  newsletterTypes: false,
  signinPromptOpen: false,
};

export const getIndustries = createAsyncThunk(
  'global/getIndustries',
  async () => {
    return (await client.getIndustries()).data;
  }
);

export const getNewsletterTypes = createAsyncThunk(
  'global/getNewsletterTypes',
  async () => {
    return (await client.getNewsletterTypes()).data;
  }
);

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setSigninPromptOpen: (state, action) => {
      state.signinPromptOpen = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIndustries.fulfilled, (state, action) => {
        state.industries = action.payload.sort((a, b) => a.name.localeCompare(b.name));
      })
      .addCase(getNewsletterTypes.fulfilled, (state, action) => {
        state.newsletterTypes = action.payload.sort((a, b) => {
          if (a.name === "Other") {
            return 1;
          }
          return a.name.localeCompare(b.name)
        });
      })
  }
});

// TODO: Use an entity adapter
export const selectIndustries = (state) => state.global.industries;
export const selectNewsletterTypes = (state) => state.global.newsletterTypes;
export const selectIndustry = (industryId) => (state) => state.global.industries.first((industry) => industry.id === industryId);
export const selectNewsletterType = (newsletterTypeId) => (state) => state.global.newsletterTypes.first((nt) => nt.id === newsletterTypeId);
export const selectSigninPromptOpen = (state) => state.global.signinPromptOpen;

export const {setSigninPromptOpen} = globalSlice.actions;

export default globalSlice.reducer;