import client from "./client";

export async function uploadImage(file) {

  if (!(file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg" )) {
    throw Error("Must upload a png or jpg file");
  }

  // TODO: Also check size

  // Next, take the image and upload to that URL
  const formData = new FormData();
  //formData.append('Content-Type', file.type);

  formData.append('file', file); // The file has be the last element

  const response = await client.uploadImage(file.type, formData);

  // Finally, return the object key, which is the reference to use
  return response.data.objectKey;

}

export function getImageSrc(objectKey) {
  return `https://newslettersurf.s3.us-east-2.amazonaws.com/${objectKey}`;
}

export function getNewsletterPage(newsletter) {
  return '/newsletter/' + newsletter.slug;
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}