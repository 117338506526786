import {getImageSrc} from "../utilities";
import {IndustryBadge} from "./IndustryBadge";
import Rating from "./Rating";
import {useNavigate} from "react-router-dom";


export default function SponsorNewsletterCard({newsletter}) {

  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate('/newsletter/' + newsletter.slug)}
      className="flex flex-row p-4 transition-all duration-300 ease-in-out hover:bg-gray-100 rounded-lg mt-2 cursor-pointer">
      <div className="pl-4 flex-1">
        <div className="font-bold text-md">{newsletter.name}</div>
        <div className="flex flex-row mt-2">
          <IndustryBadge industry={newsletter.industry} />
        </div>
      </div>
      <img src={getImageSrc(newsletter.logoReference)} alt="newsletter logo" className=" w-12 h-12 rounded-md" />
    </div>
  )
}