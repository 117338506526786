import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client, {signOut} from "../client";

const initialState = {
  profile: null,
  loadingProfile: false
};

export const getProfile = createAsyncThunk(
  'user/getProfile',
  async () => {
    return (await client.getProfile()).data;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.loadingProfile = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.loadingProfile = false;
        state.profile = action.payload;
        window.posthog.identify(
          action.payload.email,  // Replace 'distinct_id' with your user's unique identifier
          { email: action.payload.email, name: action.payload.name }
        );
      })
      .addCase(getProfile.rejected, (state) => {
        state.loadingProfile = false;
      });
  }
});

export const selectProfile = (state) => state.user.profile;

export const {} = userSlice.actions;

export default userSlice.reducer;